import React from "react";
import { useEffect, useState } from "react";
import Countdown from 'react-countdown';

import { generateTree } from "./util/nftree.js";

import treeImg from "./tree.png";
import stockingImg from "./stocking.png";

import tftLogo from "./tft-official-logo.svg";
import mawLogo from "./make-a-wish-logo.png";
import sjLogo from "./sj-logo.webp";
import nkhLogo from "./NKH_logo_color_thumb_1.jpeg";

import snowglobeImg from "./snow-globe-tree.png";

import jingleAudio from "./jingle_single.mp3";

const SantaOOO = () => {

  useEffect(async () => {
    generateTree();
  }, []);

  const Completionist = () => <span>You are good to go!</span>;

  // Renderer callback with condition
  const countdownRenderer = ({ days, hours, minutes, seconds, milliseconds, completed}) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return <div>{days} days, {hours} hours, {minutes} minutes</div>;
    }
  };

  return (
    <div id="container">

      <h2>Get in the Christmas spirit with some holiday decor for your wallet</h2>

      <div id="stocking" className="card">
        <h3>Stocking</h3>
        <div><img src={stockingImg} height="200" alt="Stocking NFT" /></div>
        <div>Soft, sock-shaped, perfect for hiding a small gift</div>
        <button>Mint now: 0.01 ETH</button>
      </div>

      <div id="tree" className="card">
        <h3>Non-Fungible Tree</h3>
        <div id="canvas1"></div>
        <div>Unique, collectible, fragrant, plenty of room for a large gift ;)</div>
        <button>Mint now: 0.1 ETH</button>
      </div>


      <div className="charities">
        <div><strong>100%</strong> of net-proceeds will be donated to charities supporting children.</div>
        Short-list of charities coming soon! Join us in <a>North Pole DAO's workshop</a> with suggestions. 
        <em>If you have experience making verifiable donations from a wallet to real-world charities, please get in touch.</em>
      
        <h3>Potential Recipients</h3>

        <a href="https://www.toysfortots.org/"><img src={tftLogo} alt="Toys For Tots" /></a>
        <a href="https://wish.org/"><img src={mawLogo} alt="Make A Wish" /></a>
        <a href="https://www.nokidhungry.org/"><img src={nkhLogo} alt="No Kid Hungry" /></a>
        <a href="https://www.stjude.org/"><img src={sjLogo} alt="St. Jude Children's Hospital" /></a>
      </div>

      <footer style={{marginTop: 250, textAlign: 'center'}}>
        <h4>This project proudly brought to you by <a href="https://northpoledao.com">North Pole DAO</a></h4>
        <a href="https://northpoledao.com">
          <img src={snowglobeImg} alt="North Pole DAO snow globe" style={{width: 200}} />
        </a>
      </footer>
    </div>
  );
};

export default SantaOOO;
