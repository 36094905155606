import Two from "two.js";
// import chroma from "chroma-js";
// import { interpolateTurbo } from "d3-scale-chromatic";

import * as d3Color from 'd3-color'
import * as d3Interpolate from 'd3-interpolate'
import * as d3ScaleChromatic from 'd3-scale-chromatic'

const generateTree = () => {
  const width = 500;
  const height = 500;

  // Make an instance of two and place it on the page.
  var params = { width: width, height: height };
  // var elem = document.body;
  var elem = document.getElementById('canvas1');
  var two = new Two(params).appendTo(elem);
   
  let baseX = width/2;
  let baseY = height/4*3;
  let treeHeight = 200 + Math.floor(Math.random() * 200); //height/2;
  let treeWidth = 300 + Math.floor(Math.random() * 150); //width/2;
  let stumpHeight = height/30 + Math.random() * 30;
  let stumpWidth = width/30 + Math.random() * 30;

  let stepJump = Math.random() * 30 + 10;

  const schemes = [
    d3ScaleChromatic.interpolateTurbo,
    d3ScaleChromatic.interpolateViridis,
    d3ScaleChromatic.interpolateInferno,
    d3ScaleChromatic.interpolateMagma,
    d3ScaleChromatic.interpolatePlasma,
    d3ScaleChromatic.interpolateCividis,
    d3ScaleChromatic.interpolateWarm,
    d3ScaleChromatic.interpolateCool,
    d3ScaleChromatic.interpolateCubehelixDefault,
    d3ScaleChromatic.interpolateBuGn,
    d3ScaleChromatic.interpolateBuPu,
    d3ScaleChromatic.interpolateOrRd,
    d3ScaleChromatic.interpolatePuBuGn,
    d3ScaleChromatic.interpolatePuRd,
    d3ScaleChromatic.interpolateRdPu,
    d3ScaleChromatic.interpolateYlGnBu,
    d3ScaleChromatic.interpolateYlGn,
    d3ScaleChromatic.interpolateYlOrBr,
    d3ScaleChromatic.interpolateYlOrRd,
    d3ScaleChromatic.interpolateRainbow,
    d3ScaleChromatic.interpolateSinebow,
  ];
  const scheme = schemes[Math.floor(Math.random() * schemes.length)];

  // mirrored
  // inverted
  // striped
  // mirror contrast
  // ornaments
  // gradient
  // star

  const invertScheme = Math.random() > 0.5;

  // var orn = two.makeCircle(500,700,50)
  // console.log(orn, orn.fill)
  // orn.fill('#000000')

  for (var step=0; baseX-(treeWidth/2)+step < baseX; step += stepJump) {
    var left = two.makePath(
      baseX, baseY, 
      baseX, baseY-treeHeight+(step*(treeHeight/(treeWidth/2))), 
      baseX-(treeWidth/2)+step, baseY
    );
    // left.fill = `rgb(${Math.floor(Math.random()*255)}, ${Math.floor(Math.random()*255)}, ${Math.floor(Math.random()*255)})`;
    // left.fill = d3ScaleChromatic.interpolateTurbo(step/(width/4));
    left.fill = scheme(1-(step/(width/4)));
    left.noStroke();
    var right = two.makePath(
      baseX, baseY, 
      baseX, baseY-treeHeight+(step*(treeHeight/(treeWidth/2))), 
      baseX+(treeWidth/2)-step, baseY
    );
    // right.fill = d3ScaleChromatic.interpolateViridis(step/(width/4));
    // right.fill = eval('d3ScaleChromatic.' + scheme + '(' + step/(width/4) + ')');
    right.fill = scheme(1-(step/(width/4)));
    right.noStroke();
  }

  // if (Math.random() > 0.5) {
  //   for (var i=0; i < 100; i++) {
  //     let x = baseX/2 + Math.floor(Math.random() * treeWidth);
  //     let y = baseY/2 + Math.floor(Math.random() * treeHeight);
  //     let radius = Math.floor(Math.random() * 30);
  //     var c = two.makeCircle(x, y, radius);
  //     // console.log(x,y,radius, c)
  //     c.fill = "#FFFFFF";
  //     c.noStroke();
  //   }
  // }

  // for (var i=0; i < 10; i++) {
  //   var y = 300 + (i*50);
  //   var anchors = [];
  //   for (var x = 200; x < 800; x += 50) {
  //     anchors.push(new Two.Anchor(x,y));
  //     y += Math.round(Math.random() * 50) - 25;
  //   }
  //   var c = two.makeCurve(anchors);
  //   c.linewidth = 5;
  //   c.noFill();
  // }

  var stump = two.makeRectangle(baseX, baseY + stumpHeight/2, stumpWidth, stumpHeight);
  stump.fill = scheme(Math.random());
  stump.noStroke();

  // const ctx = canvas.getContext('2d');

  two.update();
 
}

export { generateTree };
