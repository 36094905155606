import SantaOOO from './SantaOOO'
import './App.css';

function App() {
  return (
    <div className="App">
      <SantaOOO></SantaOOO>
    </div>
  );
}

export default App;
